<template>
  <v-card class='rounded-lg elevation-3 ma-5 pa-5'>
    <v-card-title class='font-weight-bold headline pa-0 mb-8'>General Settings</v-card-title>
    <v-card-title class='font-weight-bold pa-0 mb-8 px-11'>Login Page Fields</v-card-title>
    <v-row class='px-11 row-content'>
      <v-col
        v-for='page in generalPage'
        :key='page.pageId'
        class='custom-column col-lg-4'
      >
        <v-card-subtitle class='pa-0 title'>{{ page.pageName }}:</v-card-subtitle>
        <v-row class='ma-0'>
          <v-col
            v-for='content in page.content'
            :key='content.id'
            class='px-0'
            cols='12'
          >
            <v-form
              ref='generalSectionTitle'
              lazy-validation
            >
              <page-section-content
                :activeEdit='onActiveEdit'
                :focused='focused'
                :inputEditId='inputEditId'
                :is-general-settings='true'
                :section='content'
                @activeChanged='onActiveEdit'
                @changeEditId='onEditIdChange'
                @focusChanged='onFocusChanged'
                @saveTitle='saveTitle'
              />
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class='justify-end px-11'>
      <v-btn
        :loading='loadingSave'
        color='primary'
        depressed
        min-width='250'
        @click='onSave'
      >
        Save
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import PageSectionContent from '../components/PageSectionContent'

export default {
  name: 'GeneralSettingsPage',
  components: {PageSectionContent},
  data: () => ({
    inputEditId: -1,
    sectionsData: [],
    sectionsDataImage: [],
    loadingSave: false,
    focused: false,
    activeEdit: false
  }),
  computed: {
    ...mapGetters('cmsStore', ['getGeneralSettingsContent']),
    generalPage() {
      return this.getGeneralSettingsContent
    }
  },
  methods: {
    ...mapActions('cmsStore', ['sendSectionData', 'getCMSContent', 'sendSectionDataImage', 'getLoginContent']),
    onFocusChanged(value) {
      this.focused = value
    },
    onActiveEdit(value) {
      this.activeEdit = value
    },
    async updateImage(images) {
      images.map(async image => {
        const formData = new FormData()
        formData.append('image', image.content, {
          type: image.content.type
        })
        const data = {
          data: formData,
          id: image.sectionId
        }
        await this.sendSectionDataImage(data)
      })
    },
    async onSave() {
      let validations = []
      for (const key in this.$refs.generalSectionTitle) {
        this.$refs.generalSectionTitle[key].validate()
        validations.push(this.$refs.generalSectionTitle[key].validate())
      }
      const isValid = validations.every(el => el)
      if (isValid) {
        this.loadingSave = true
        await this.updateImage(this.sectionsDataImage)
        await this.sendSectionData(this.sectionsData)
        await this.getCMSContent()
        await this.getLoginContent()
        this.sectionsDataImage = []
        this.sectionsData = []
        this.loadingSave = false
      }
    },
    saveTitle(content, sectionId) {
      const objContent = {sectionId, content}
      !(content instanceof Object)
        ? this.sectionsData.push(objContent)
        : this.sectionsDataImage.push(objContent)
    },
    onEditIdChange(sectionId) {
      this.inputEditId = sectionId
    }
  }
}
</script>

<style lang='scss' scoped>
//.custom-column:last-child {
//  margin-top: -200px !important;
//}
@media (max-width: 1264px) {
  .custom-column:last-child {
    margin-top: 0 !important;
  }
  .row-content {
    flex-direction: column;
  }
}
</style>